body {
  background: #222222;
  color: #fff;
  height: 100vh;
  width: 100vw;
  margin: 0;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'poppins', sans-serif;
  overflow: hidden;
}

section {
  display: flex;
  height: 100vh;
  justify-content: space-between;
}

h1 {
  font-family: "Ysabeau SC", sans-serif;
  font-weight: 300;
  font-size: 12vw;
  margin: 0 20px;
  line-height: 0.8;
}

h2 {
  bottom: 0;
  position: fixed;
  font-size: 24px;
  line-height: 1.2;
  margin: 0 20px 80px 20px;
  font-weight: 500;
}

h3 {
  bottom: 0;
  position: absolute;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  width: 100%;
  font-weight: 400;
  opacity: 80%;
}

a {
  text-decoration: none;
  box-shadow: inset 0 0 0 0 #5F22D9;
  color: #fff;
  margin: 0 -0.15rem;
  padding: 0 0.25rem;
  transition:
    color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}
a:hover {
  box-shadow: inset 100px 0 0 0 #5F22D9;
  color: white;
}

header {
  width: 50vw;
  position: relative;
}

figure {
  background: #fff;
  margin: 0;
  position: relative;
  width: 50vw;
}

figcaption {
  font-size: 36px;
  font-weight: normal;
  position: absolute;
  text-align: center;
  z-index: 1;
  bottom: 0;
  color: #fff;
  width: 100%;
  padding: 20px 0;
}

img {
  height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

@media (max-width: 800px) {
  section {
    flex-direction: column;
  }

  figure {
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
  }


  h2 {
    font-size: 16px;
    position: relative;
    margin: 32px 20px 20px;
    font-weight: 300;
  }

  h3 {
    font-size: 12px;
    position: fixed;
    margin: 0 0 20px 0;
    text-align: center;
    z-index: 1;
  }

  figcaption {
    position: relative;
    padding: 10px 0;
    font-size: 16px;
  }
}
